import React from 'react';
import { useNav } from '../customHooks/useNav';
import './project.css';
import skillsIcon from "../icons/skillsLogo.jpg"
import bg from "../icons/bg_2.jpeg"

import advanced_excel from "../icons/skills/advanced_excel.png"
import dbt from "../icons/skills/dbt.png"
import google_analytics from "../icons/skills/google_analytics.png"
import pipedrive from "../icons/skills/pipedrive.png"
import ms_sql from "../icons/skills/ms_sql.png"
import power_bi from "../icons/skills/power_bi.png"
import netsuite from "../icons/skills/netsuite.png"
import predictive_analytics from "../icons/skills/predictive_analytics.png"
import python from "../icons/skills/python.png"
import r_programming from "../icons/skills/r_programming.png"
import snowflake from "../icons/skills/snowflake.png"
import tableau from "../icons/skills/tableau.png"

const Skills = () => {
	const skillsRef = useNav('Skills');
	return (
		<section ref={skillsRef} id='skillsContainer' style={{backgroundColor:"rgb(202, 225, 240)"}}>
           <div>
                <p style={{marginLeft:"18vw"}}>
                <img className="portfolioLinks" style={{ width: "auto", height: "18vw", marginTop:"16vw", marginLeft:"-12vw", marginRight:"9vw"}} src={skillsIcon} alt="shreya" />
                    <div >
                        <img className="portfolioLinks" style={{ width: "11vw", height: "10vw"}} src={ms_sql} alt="shreya" />
                        <a href="https://public.tableau.com/app/profile/shreya.nadkarni"><img className="portfolioLinks" style={{ width: "14vw", height: "8vw", marginTop:"4vw"}} src={tableau} alt="shreya" /></a>
                        <img className="portfolioLinks" style={{ width: "15vw", height: "6vw", marginTop:"4.5vw"}} src={advanced_excel} alt="shreya" />
                        <img className="portfolioLinks" style={{ width: "15vw", height: "5vw", marginTop:"4.5vw"}} src={google_analytics} alt="shreya" />
                    </div>
                    <div>
                        <img className="portfolioLinks" style={{ width: "16vw", height: "5vw", marginTop:"4.5vw", marginLeft:"3.5vw"}} src={power_bi} alt="shreya" />
                        <img className="portfolioLinks" style={{ width: "13vw", height: "8vw", marginTop:"5vw",marginLeft:"4.3vw"}} src={netsuite} alt="shreya" />
                        <img className="portfolioLinks" style={{ width: "16vw", height: "4.5vw", marginTop:"5vw",marginLeft:"3.2vw"}} src={python} alt="shreya" />
                        <img className="portfolioLinks" style={{ width: "7vw", height: "5vw", marginTop:"4.5vw",marginLeft:"6.2vw"}} src={r_programming} alt="shreya" />
                    </div>
                    <div>
                        <img className="portfolioLinks" style={{ width: "11vw", height: "5vw", marginTop:"4.5vw",marginLeft:"5vw"}} src={dbt} alt="shreya" />
                        <img className="portfolioLinks" style={{ width: "18vw", height: "4.5vw", marginTop:"4.5vw",marginLeft:"3vw"}} src={snowflake} alt="shreya" />
                        <img className="portfolioLinks" style={{ width: "18vw", height: "4.5vw", marginTop:"3.5vw",marginLeft:"3vw"}} src={pipedrive} alt="shreya" />
                        <img className="portfolioLinks" style={{ width: "12vw", height: "12vw", marginTop:"4.2vw",marginLeft:"6vw"}} src={predictive_analytics} alt="shreya" />
                    </div>
                </p>
            </div>
		</section>
	);
};

export default Skills;
  