import React, {useState} from "react";
import shreya from'../icons/shreya.jpeg';
import Contact from "./Contact";
import { ArrowRightIcon, MailIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import icon from "../icons/icon.png"
import { useNav } from '../customHooks/useNav';
import './Page.css';
import tableauPortfolio from "../icons/tableau_portfolio.jpg"
import LinkedinProfile from "../icons/LinkedinLogo.jpg"
import mail from "../icons/mailLogo.jpg"
import home_bg from "../icons/home_bg.jpeg"
import bg from "../icons/bg_1.jpeg"
import tableau from "../icons/skills/tableau.png"
import Styled from "styled-components";


const Home = () => {
    const homeRef = useNav('Home');
    
    return (
        // Other sections of home page
        <section ref={homeRef} id='homeContainer' style={{backgroundImage:'url('+bg+')',backgroundSize:"cover"}}>
			<div style={{padding:"11vw"}}>
                <p><img className="profileImage" src={icon} alt="shreya"/>
				<h3 className="homeFont"><b>SHREYA NADKARNI</b></h3></p>
                <p className="homeFont2" style={{marginTop:"1vw"}}>Data Analyst | Graduate Student at DePaul University focusing on Data Analytics</p>
                <p className="homeFont2" style={{marginTop:"1vw", marginLeft:"14vw"}}><i>“The goal is to turn data into information, and information into insight.”</i></p>
                <p>
                    <a href="mailto:shreya.nadkarni1998@gmail.com">
                        <img className="portfolioLink"   src={mail} alt="shreya"/>
                    </a>
                    <a href="https://www.linkedin.com/in/shreya-nadkarni/">
                        <img className="portfolioLink" style={{marginLeft:"2vw"}}src={LinkedinProfile} alt="shreya"/>
                    </a>
                    <a href="https://public.tableau.com/app/profile/shreya.nadkarni">
                        <img className="portfolioLink" style={{marginLeft:"2vw"}} src={tableau} alt="shreya" />
                    </a>
                </p>
                <br/>      
            </div>
        </section>
    );
};

export default Home;
// style={{marginLeft:"20vw", color:"rgb(21, 59, 84)"}}>Data Analyst | Graduate Student at DePaul University focusing on Data Analytics</p>
//                 <p className="homeFont2" style={{marginLeft:"22vw", color:"rgb(9, 4, 28)"}}><i>“The goal is to turn data into information, and information into insight.”</i></p>
//                 <p className="homeFont2" style={{marginLeft:"30vw"}}></p>