import React from 'react';
import { Home, About, Contact } from './';
import {Projects, Experience, Education, Skills} from './';

const Main = () => (
	<main>
		<Home />
		<About />
        <Projects />
        <Education />
        <Experience />
        <Skills />
	</main>
);

export default Main;