import React, { useState } from 'react';
import { useNav } from '../customHooks/useNav';
import './project.css';
import projectLogo from '../icons/projectLogo.jpg'
import bg from "../icons/bg_2.jpeg"
import { NavContext } from '../context/NavContext';


import ExcelAnalysis1 from './projects/Advanced_Excel/ExcelAnalysis1.png'
import ExcelAnalysis2 from './projects/Advanced_Excel/ExcelAnalysis2.png'
import ExcelConclusion from './projects/Advanced_Excel/ExcelConclusion.png'

import ExcelModelA from './projects/Advanced_Excel/ExcelModelA.png'
import ExcelModelB from './projects/Advanced_Excel/ExcelModelB.png'

import DQLAB_performance_year from './projects/DQLAB/DQLAB_performance_year.png'
import DQLAB_performance_subcategory from './projects/DQLAB/DQLAB_performance_subcategory.png'
import DQLAB_promotion_effectiveness_year from './projects/DQLAB/DQLAB_promotion_effectiveness_year.png'
import DQLAB_promotion_effectiveness_subcategory from './projects/DQLAB/DQLAB_promotion_effectiveness_subcategory.png'
import DQLAB_transactions_year from './projects/DQLAB/DQLAB_transactions_year.png'
import DQLAB_new_consumers from './projects/DQLAB/DQLAB_new_consumers.png'

import PopRunner_gender_distribution from './projects/pop_runner/PopRunner_gender_distribution.png'
import PopRunner_loyalty_distribution from './projects/pop_runner/PopRunner_loyalty_distribution.png'
import PopRunner_query1 from './projects/pop_runner/PopRunner_query1.png'
import PopRunner_sales from './projects/pop_runner/PopRunner_sales.png'
import PopRunner_query2 from './projects/pop_runner/PopRunner_query2.png'
import PopRunner_query3 from './projects/pop_runner/PopRunner_query3.png'

import RProject from './projects/USArrests_RScript.html'

//    ---------------------------------------------------------------------------- Display ----------------------------------------------------------------------------
function Projects() {
    const [showDisplayProjects, setShowDisplayProjects] = useState(true)
    const [showProjectA, setShowProjectA] = useState(false)
    const [showProjectB, setShowProjectB] = useState(false)
    const [showProjectC, setShowProjectC] = useState(false)
    const [showProjectD, setShowProjectD] = useState(false)
    const [showProjectButtons, setShowProjectButtons] = useState(true)
    const [style, setStyle] = useState("projectsize1")
    const [projectAStyle, setProjectAStyle] = useState("projectA")
    const [projectCStyle, setProjectCStyle] = useState("projectC")
    const [projectBStyle, setProjectBStyle] = useState("projectB")
    const [projectButtonStyle, setProjectButtonStyle] = useState("projectButtons")
    

    // ---------------------------------------------------------------------------- Project A ----------------------------------------------------------------------------
    const projectA = async () => {
        setStyle("projectsize2");
        setShowProjectButtons(false);
        setShowProjectA(true);
        document.getElementById('projectA').scrollIntoView({ behavior: 'smooth' });
        
        };
    // ---------------------------------------------------------------------------- Project B ----------------------------------------------------------------------------
        const projectB = async () => {
        setStyle("projectsize2");
        setShowProjectButtons(false);
        setShowProjectB(true);
        document.getElementById('projectB').scrollIntoView({ behavior: 'smooth' });
        };
    
    // ---------------------------------------------------------------------------- Project C ----------------------------------------------------------------------------
        const projectC = async () => {
        setStyle("projectsize2");
        setProjectCStyle("projectC");
        setShowProjectButtons(false);
        setShowProjectC(true);
        document.getElementById('projectC').scrollIntoView({ behavior: 'smooth' });
        };
    
    // ---------------------------------------------------------------------------- Project D ----------------------------------------------------------------------------
        const projectD = async () => {
        setStyle("projectsize2");
        setShowProjectButtons(false);
        setShowProjectD(true);
        };
    
    // ------------------------------------------------------------------------------ Clear -------------------------------------------------------------------------------
        const clear = async() => {
        setShowProjectButtons(true);
        setShowProjectA(false);
        setShowProjectB(false);
        setShowProjectC(false);
        setShowProjectD(false);
        setStyle('projectsize1');
        document.getElementById('projectContainer').scrollIntoView({ behavior: 'smooth' });
        };

	const projectsRef = useNav('Projects');

	return (
		<section className={style} ref={projectsRef} id='projectContainer' style={{backgroundColor:"rgb(202, 225, 240)"}}>
            <p id='projects' className='smallp'>
                <div className={projectButtonStyle}>
                    {showProjectButtons ? (
                    <p className="projectButtonAlign">
                        <img style={{height:"32vw", width:"34vw", marginTop:"5vw",marginLeft:"1.5vw"}} src={projectLogo} alt="shreya" />
                        <div style={{marginLeft:"1.5vw"}}>
                            <button className='Button' onClick={projectA}> Forecasting Airport Passenger Arrival using Excel </button>
                            <button className='Button' style={{marginTop:"1vw"}} onClick={projectB}> DQLAB Data Analysis using SQL </button>
                            <button className='Button' style={{marginTop:"1vw"}} onClick={projectC}> Pop Runner Project using SQL</button>
                            <button className='Button' style={{marginTop:"1vw"}} onClick={()=> window.location.href='https://public.tableau.com/app/profile/shreya.nadkarni'}> Tableau Projects </button>
                        </div>
                        <div style={{marginLeft:"1.5vw"}}>
                            <button className='Button' style={{marginTop:"2vw"}} onClick={()=> window.location.href='https://shreya-nadkarni1605.github.io/FuelEfficiency_project_R/Fuel_Eff_RScript.html'}> Regression, Visualization & Descriptive Statistics using R </button>
                            <button className='Button' style={{marginTop:"1vw"}} onClick={()=> window.location.href='https://shreya-nadkarni1605.github.io/ShopCO_project_R/ShopCO_Rscript.html'}> Predictive Analytics using R : <br/>Regression, Classification, Clustering </button>
                            <button className='Button' style={{marginTop:"1vw"}} onClick={()=> window.location.href='https://shreya-nadkarni1605.github.io/predictive_analytics_using_R/USArrests_RScript.html'}> Clustering Analysis using R:<br/> K-Means, Hierarchical </button>                            
                        </div>
                    </p>
                    ) : null}
                {/* -------------------------------------------------------------------------  Project A   --------------------------------------------------------------------------------------------------------------------- */}
                    {showProjectA ? (
                    <div className={projectAStyle}>
                        <div id='projectA'>
                            <div>
                            <h4><b>Forecasting Airport Passenger Arrival</b></h4>
                            <br/>
                            Skills used: Advance Excel tools
                            <br/>
                            <b>Summary:</b>
                            Airports have always faced the problem of long waiting lines and waiting times at security gates. 
                            In their efforts to reduce waiting lines and times, or at least to not have them become longer as 
                            airline demand increases, airports have analyzed their existing security systems and sought quantitative 
                            solutions. One of the key components of any effort to operationally improve airport security procedures is 
                            forecasting passenger arrivals at security checkpoints to determine how many security checkpoints and staff 
                            are needed. At Berry International Airport (BEI), security analysts would like to forecast passenger arrivals 
                            for next July, the airport’s busiest travel month of the year, for the purpose of determining how many 
                            security checkpoints they should staff during the month so that waiting lines and times will not be 
                            excessively lon <br/><br/>
                            <b>Objective:</b>
                            Forecast passenger arrivals to help improve the waiting times at Berry International Airport.<br/>
                            <b>• Question 1:</b> What are the forecasted passenger arrivals at BEI during each time period for 1 day 
                            in July of Year 4?<br/>
                            <b>• Question 2:</b> Is the current number of security checkpoints sufficient to keep the time in system 
                            under 5 minutes for each time interval? <br/>
                            –	If not, how should the checkpoints be changed?
                            <br/><br/>
                            
                            <b style={{fontSize:"1.5vw"}}>Solution:</b>
                            </div>
                                <p>
                                <img className="portfolioLinks"  style={{height:"28vw", width:"auto"}} src={ExcelModelA} alt="project" />
                                <img className="portfolioLinks"  style={{height:"28vw", width:"auto"}} src={ExcelModelB} alt="project" />
                                </p>
                                <p>
                                <img className="portfolioLinks"  style={{height:"28vw", width:"auto",marginTop:"1vw"}} src={ExcelAnalysis1} alt="project" />
                                <img className="portfolioLinks"  style={{height:"28vw", width:"auto",marginTop:"1vw"}} src={ExcelAnalysis2} alt="project" />
                                </p>
                                <p>
                                <img className="portfolioLinks"  style={{height:"28vw", width:"auto", marginLeft:"24vw",marginTop:"1vw"}} src={ExcelConclusion} alt="project" />
                                </p>
                        </div>
                        <br/>
                        <br/>
                        <button className='Button' onClick={clear} style={{marginLeft:"30vw"}}> Close </button>
                    </div>
                    ) : null}

                    {/* ------------------------------------------------------------------------- Project B ------------------------------------------------------------------------------------------------------------- */}
                    {showProjectB ? (
                    <div className={projectBStyle} >
                        <div id='projectB' style={{marginTop:"20vw"}}>
                            <h4><b>DQLAB Data Analysis using SQL</b></h4>
                            <br/>
                            <b>Dataset Brief:</b>
                            The dataset used contains transactions from 2009 to 2012 with a total of raw data is 5500, including order status which is 
                            divided into order finished, order returned, and order cancelled.
                            Airports have always faced the problem of long waiting lines and waiting times at security gates. 
                            In their efforts to reduce waiting lines and times, or at least to not have them become longer as 
                            airline demand increases, airports have analyzed their existing security systems and sought quantitative 
                            solutions. One of the key components of any effort to operationally improve airport security procedures is 
                            forecasting passenger arrivals at security checkpoints to determine how many security checkpoints and staff 
                            are needed. At Berry International Airport (BEI), security analysts would like to forecast passenger arrivals 
                            for next July, the airport’s busiest travel month of the year, for the purpose of determining how many 
                            security checkpoints they should staff during the month so that waiting lines and times will not be 
                            excessively lon.
                            <br/><br/>
                            <b>Project Objective:</b>
                            DQ Lab store management wants to know: <br/>
                            1.	Order numbers and total sales from 2009 until 2012 which order status is finished. <br/>
                            2.	Total Sales by Sub-Category in 2011 and 2012.  <br/>
                            3.	Promotion Effectiveness and Efficiency by Years.  <br/>
                            4.	Promotion Effectiveness and Efficiency by Product Sub-Category.  <br/>
                            5.	Customers Transactions per Year.  <br/>
                            6.	New customers over the years.  <br/>
                            <br/><br/>
                            <b style={{marginLeft:"4vw", fontSize:"Large"}}>Solutions:</b>

                                <p>
                                    <img className="portfolioLinks"  style={{height:"25vw", width:"38vw"}} src={DQLAB_performance_year} alt="project" />
                                    <div style={{marginTop:"2vw"}}>
                                    Order numbers and total sales from 2009 until 2012 which order status is finished. <br/>
                                    Total sales each year tends to increase, however the sales in 2012 were not greater than total sales in 2009. Secondly the number of 
                                    orders finished each year tended to increase but not significantly.
                                    </div>
                                </p>

                                <p>
                                    <img className="portfolioLinks"  style={{height:"25vw", width:"38vw"}} src={DQLAB_new_consumers} alt="project" />
                                    <div>
                                    New customers over the years.<br/>
                                    The number of new customers who make transactions each year decreases. This indicates that the sales received so far have come from existing customers.
                                    </div>
                                </p>

                                <p>
                                    <img className="portfolioLinks"  style={{height:"30vw", width:"60vw"}} src={DQLAB_performance_subcategory} alt="project" />
                                    <div>
                                    Total Sales by Sub-Category in 2011 and 2012. <br/>
                                    The figure allows us to understand that there is an increase and decrease in total sales for each product subcategory in one year. 
                                    The highest total sales in 2011 and 2012 were still dominated by product subcategories that tended to be the same, it is chairs and chair mats, 
                                    office machines, and tables, while the lowest total sales are labels, scissors, rulers and trimmers, and rubber bands.
                                    </div>
                                </p>
                                <p>
                                    
                                    <img className="portfolioLinks"  style={{height:"32vw", width:"68vw"}} src={DQLAB_promotion_effectiveness_subcategory} alt="project" />
                                    <div>
                                    Promotion Effectiveness and Efficiency by Product Sub-Category. <br/>
                                    Based on Figure 4, in 2012 there were only a few product subcategories with a burn rate percentage lower than 4.5%. 
                                    Meanwhile, several product subcategories with a burn rate greater than 4.5% had low sales rates.
                                    </div>                                
                                </p>
                                
                                
                                <p>
                                    <img className="portfolioLinks"  style={{height:"25vw", width:"38vw"}} src={DQLAB_promotion_effectiveness_year} alt="project" />
                                    <div>
                                    Promotion Effectiveness and Efficiency by Years.<br/>
                                    To understand the effectiveness, I calculated the burn rate using the formula:
                                    Burn rate = (total discount/total sales) x 100. The effectiveness and efficiency of the promotions was analyzed based on the burn rate 
                                    by comparing the total value of the promotions to the total sales earned. 
                                    The figure 3 allows us to evaluate that every year the percentage of burn rate tends to increase, which means that promotion costs continue 
                                    to increase every year. The impact of increased promotion costs is to increase in total sales, but the promotion that has increased sales cannot 
                                    be said to be effective and efficient because the percentage of burn rate exceeds the maximum expected rate at 4.5%.
                                    </div>
                                </p>
                                <p>
                                    <img className="portfolioLinks"  style={{height:"25vw", width:"38vw"}} src={DQLAB_transactions_year} alt="project" />
                                    <div>
                                    Customers Transactions per Year.<br/>
                                    The numbers state that the customer transactions per year has increased but not significantly. Thus, it can be 
                                    appropriate to say that the promotional effectiveness has not been that impactful as expected. Additional strategies are needed in place to increase the transaction rate.
                                    </div>
                                </p>
                        </div>
                        <br/>
                        <br/>
                        <button className='Button' onClick={clear} style={{marginLeft:"30vw"}}> Close </button>
                    </div>
                    ) : null}
                    {/* ----------------------------------------------------------------------------  Project C  ---------------------------------------------------------------------------------------------- */}
                    {showProjectC ? (
                    <div className={projectCStyle}>
                        <div>
                            <h4><b>Do Pop-Ups and Emails Drive Sales a deep dive.</b></h4>
                            <br/>
                            <b>Executive Summary:</b>
                            What is the point of popups? My research proves that there is no benefit other than annoying 
                            the end user. This report will document the steps I took to analyze and determine the effectiveness of 
                            Pop Runner’s two advertising campaigns, pop-ups, and email blasts. During the analysis, I investigated 
                            consumer demographics, consumer habits and spending trends, which allowed me to recommend how Pop-Runner 
                            should market to their consumers. While comparing the spending trends for each marketing campaign, the 
                            research showed that the Email blast, when opened, led to an increase in consumer spending, However, the 
                            pop-up campaign was not successful because it led to a decrease in sales. 
                            <br/><br/>
                            <b>Project Objective: </b>
                            The objective of this project is to analyze Pop Runner Data to see how their consumers are interacting 
                            with their website. The result of this analysis will determine if the advertisements are effective and 
                            if they are a worthwhile investment <br/>
                            Questions:<br/>
                            1.	Which advertising campaign was more effective? 
                            2.	The key demographics of the Pop Runner’s consumers?
                            3.	How can Pop Runner increase Sales? 
                            4.	If Pop Runner was to invest more money, where would it be most effective? <br/><br/>
                            <b>Solutions</b>
                            1-The key demographics of the Pop Runner’s consumers?
                                <p>
                                    <img className="portfolioLinks"  style={{height:"22vw", width:"68vw"}} src={PopRunner_gender_distribution} alt="project" />
                                    <p><br/><br/>
                                    a-	Gender of Target audience <br/>
                                    Based on gender the consumers can be classified as “Male” or “Female.” The consumer count of Female is on 
                                    the higher side (6903) as compared to male (2129), but the average age of male (32.45186) is higher than that of female (30.61394).
                                    </p>
                                </p>

                                <p>
                                    <img className="portfolioLinks"  style={{height:"22vw", width:"32vw"}} src={PopRunner_loyalty_distribution} alt="project" />
                                    <p><br/><br/>
                                    b - Consumers in each loyalty status group<br/>
                                    The Loyalty Status is assigned by the online retailer. The basis of past spending is how the consumer is assigned a loyalty status: 0-4. 
                                    0 is the lowest loyalty status and 4 is the highest loyalty status. The average age of loyalty status 4 is 33.5, which is the highest compared 
                                    with loyalty status of 0 with an average age 29.4 being the lowest. As loyalty status increases so does the average age.
                                    </p>
                                </p>
                            2 - Evaluate if online advertisement impacts sales using two advertising method- Popup message and email. <br/>

                                <p>
                                    <img className="portfolioLinks"  style={{height:"26vw", width:"64vw"}} src={PopRunner_query1} alt="project" />
                                    <p><br/><br/>
                                    a-	Determine the impact on Sales due to the pop-up. This method focused on understanding whether consumers 
                                        who received a popup message spent more money than the consumers who did not receive an email.<br/>
                                    b-	Determine the impact on Sales due to the email blast. This method focused on understanding whether 
                                        consumers who opened the email spent more money than those who did not.
                                    </p>
                                </p>
                                <p>
                                <img className="portfolioLinks"  style={{height:"22vw", width:"32vw"}} src={PopRunner_sales} alt="project" />
                                    <div>
                                    <b>Outcome for Method 1: </b>
                                    From the numbers below, we can see that the pop-up was not successful. The total ($626,324.50) and 
                                    average sales ($138.69) of the consumer who did not receive a pop-up was higher when compared to the 
                                    consumers who did receive a pop up ($594,930 and $131.74 respectively). The total sales for consumers 
                                    with no popups is $31,394 greater than for the consumers who received a popup. While the Average sales between the two group was not substantial, 
                                    the difference in the total sales were significant, indicating that the popup advertisement method was not successful.
                                    <b> Outcome for Method 2: </b>
                                    From the table below, we can say that the email blast was successful. The total sales for consumers who did not open the email was greater than 
                                    those who opened the email. However, the average spending by consumer who opened the email was 91% ($114.75) greater than the consumers who did not 
                                    open the email. We can also see from Table 2 that out of 9032 total consumers, 8316 consumers were not impacted by the email, however, 716 consumers responded well to the email blast increasing the average sales.
                                    </div>                                
                                </p>

                                3- Final Evaluation of consumer spendings. <br/>
                                Taking a deep dive into the data we can isolate a few consumers to see if they are impacted by either of the advertising campaigns. 
                                We will focus on the consumer who spent the least amount of money greater than $0.00, and the consumer who spent the most.
                                <p>
                                    <img className="portfolioLinks"  style={{height:"16vw", width:"32vw"}} src={PopRunner_query2} alt="project" />
                                    <div>
                                        <b>Lowest Spend Impacted by Pop-Up:</b>
                                        Logic: Isolate the consumer who spent the least amount of money greater than $0.00. Join the consumer table to 
                                        the Pop-up Table to see if they received a pop-up.
                                        Results: The consumer who spent the least amount of money ($0.40) was Consumer “48014673513” and they did not 
                                        receive the pop up.
                                    </div>
                                </p>

                                <p>
                                    <img className="portfolioLinks"  style={{height:"16vw", width:"32vw"}} src={PopRunner_query3} alt="project" />
                                    <div>
                                        <b>Highest Spend Impacted by Email:</b>
                                        Logic: Isolate the consumer who spent the most amount of money. Join the consumer table to the email table to see if they opened the email or not.
                                        Result: The consumer who spent the most amount of money ($ 4,673.40) was consumer “5955534353” and they did open the email.
                                    </div>
                                </p>
                        </div>
                        <br/>
                        <br/>
                        <button className='Button' onClick={clear} style={{marginLeft:"34vw"}}> Close </button>
                    </div>
                    ) : null}

                    {showProjectD ? (
                    <div dangerouslySetInnerHTML={{ __html: RProject }}/>
                    ) : null}
{/* -------------------------------------------------------------------------------------------------- THE END ------------------------------------------------------------------------------------------------------ */}
                    
                </div>
            </p>
		</section>
	);
};
export default Projects;
