import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page2.css';
import LSEIcon from "../icons/LSE_logo.jpeg"
import stAndrewsIcon from "../icons/st-andrews-logo.jpeg"
import depaulIcon from "../icons/depaul-logo.jpeg"
import bg from "../icons/bg_2.jpeg"


const Education = () => {
	const educationRef = useNav('Education');

	return (
		<section ref={educationRef} id='educationContainer' style={{backgroundColor:"rgb(202, 225, 240)"}}>
            <div className='rounded p-5'>
                <p>
                    <a href="https://business.depaul.edu/academics/management-entrepreneurship/graduate/business-analytics-ms/Pages/default.aspx">
                        <img className="portfolioLinks"  style={{width:"13vw" ,height:"14vw"}} src={depaulIcon} alt="shreya"/>
                    </a>
                    <div>
                        <h3>DePaul Kelstadt Graduate School of Business</h3>
                            Master of Science - MS, Business Analytics (Focusing on Data Analytics)
                            <br/>
                            (Sep 2021 - Mar 2022) 
                            <br/>
                            Grade: 4.0
                    </div>
                </p>

                <p>
                <br/>
                    <a href="https://www.st-andrews.ac.uk">
                        <img className="portfolioLinks" style={{width:"10vw" ,height:"15vw", marginLeft:"2.5vw"}} src={stAndrewsIcon} alt="shreya"/>
                    </a>
                    <div style={{marginLeft:"1vw"}}>
                        <h3>University of St. Andrews</h3>
                        Master's Degree, Marketing, Strategic Management & Economics
                        <br/>
                        (Sep 2019 - Aug 2020) 
                        <br/>
                        Grade: 4.0
                    </div>
                </p>
                
                <p>
                    <a href="https://www.lse.ac.uk/study-at-lse/Undergraduate/degree-programmes-2023/BSc-Economics">
                        <img className="portfolioLinks"  style={{width:"12vw" ,height:"10vw"}} src={LSEIcon } alt="shreya"/>
                    </a>
                    <div>
                        <h3>The London School of Economics and Political Science (LSE)</h3>
                            Bachelor's Degree, Economics and Business Management
                        <br/>
                        (Aug 2016 - Aug 2019) 
                        <br/>
                        Grade: 4.0
                    </div>
                </p>
            </div>
		</section>
	);
};
export default Education;
  