import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page2.css';
import IDFC_Icon from "../icons/idfc-logo.jpg"
import AV_Icon from "../icons/analytic_vizion_logo.jpg"
import Amazon_Icon from "../icons/amazon-logo.jpg"
import Tech_M_Icon from "../icons/tech-m-logo.jpg"
import bg from "../icons/bg_2.jpeg"

const Experience = () => {
	const experienceRef = useNav('Experience');
	return (
        <section ref={experienceRef} id='experienceContainer' style={{backgroundColor:"rgb(202, 225, 240)",height:"155vh"}}>
            <div className='rounded p-2'>
                <p>
                    <a href="https://www.analyticvizion.com/what-we-do">
                        <img className="portfolioLinks" style={{width:"15vw",height:"8.5vw", marginRight:"2vw", marginTop:"4vw"}} src={AV_Icon} alt="shreya" />
                    </a>
                    <div>
                        <h3 style={{marginTop:"4vw"}}>Data Analyst </h3>
                            Atlanta, Georgia, United States
                            <br/>
                            (Jun 2022 - Present)
                            <br />
                            Built out the data and reporting infrastructure from the ground up using Tableau and SQL to provide real-time insights into the product, marketing funnels, and business KPIs. <br/>
                            Implemented long-term pricing model that improved customer value by 25% using NetSuite.<br/>
                            Implemented automated ETL process by injecting data using FiveTran and performing analytics on Snowflake.<br/>
                            Worked with Data Mapping, Querying the source and target tables in database using SQL for testing purpose.
                        <br />
                    </div>
                </p>
                <p>
                    <a href="https://www.amazon.jobs/en/locations/unitedkingdom">
                        <img className="portfolioLinks" style={{width:"15vw",height:"7vw", marginRight:"2vw",marginTop:"2vw"}} src={Amazon_Icon} alt="shreya" />
                    </a>
                    <div>
                        <h3>Fulfillment Analyst </h3>
                        Edinburgh, Scotland, United Kingdom
                        <br />
                        (Aug 2020 - May 2021)
                        <br />
                        Responsible for analyzing e-commerce channel, fulfillment network performance and key metric. <br/>
                        Automated reports and tools with a combination of using SQL and advance Excel (Macros/Lookups). <br/>
                        Document the SAP transaction flow of Order to Revenue-for all inventory transactions to solve issues. <br/>
                        Uncovering trends and correlations through data mining and analysis to develop insights that can improve the business and help make effective decisions. <br/>
                        <br />
                    </div>
                </p>

                <p>
                    <a href="https://www.techmbs.in">
                        <img className="portfolioLinks" style={{width:"15vw",height:"9vw", marginRight:"2vw", marginTop:"2vw"}} src={Tech_M_Icon} alt="shreya" />
                    </a>
                    <div>
                        <h3>Marketing Analyst - Intern </h3>
                        Mumbai, Maharashtra, India
                        <br />
                        (Jun 2019 - Aug 2019)
                        <br />
                        Conducted end-to-end analyses to drive insights for marketing stakeholders around campaign performance and impact to sales pipeline progression, expansion, and customer retention. <br/>
                        Worked on large data sets, undertaking in-depth quantitative analysis, and distilling data into practical and intuitive strategies for marketing and pricing planning. <br/>
                        Conducted research on consumer behavior/consumption patterns used to implement promotional strategies. <br/>
                        Forecasted consumer behavior over a 10-year period. <br/>
                        <br/>
                    </div>
                </p>

                <p>
                    <a href="https://idfcmf.com">
                        <img className="portfolioLinks" style={{width:"15vw",height:"9vw", marginRight:"2vw", marginTop:"2vw"}} src={IDFC_Icon} alt="shreya" />
                    </a>
                    <div>
                        <h3>Economic Analyst - Intern  </h3>
                        Mumbai, Maharashtra, India
                        <br />
                        (Jun 2018 - Aug 2018)
                        <br />
                        Conducted end-to-end analyses to drive insights for marketing stakeholders around campaign performance and impact to sales pipeline progression, expansion, and customer retention. <br/>
                        Worked on large data sets, undertaking in-depth quantitative analysis, and distilling data into practical and intuitive strategies for marketing and pricing planning. <br/>
                        Conducted research on consumer behavior/consumption patterns used to implement promotional strategies. <br/>
                        Forecasted consumer behavior over a 10-year period. <br/>
                        <br />
                    </div>
                </p>
            </div>
        </section>
	);
};
export default Experience;
  