import logo from './logo.svg';
import './App.css';
import React from "react";
// import Home from "./components/Home";
// import About from "./components/About";
// import Contact from "./components/Contact";
// import Navbar from "./components/Navbar";
// import Projects from "./components/Projects";
// import Skills from "./components/Skills";
// import shreya from'./shreya.jpeg';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Nav } from './nav';
import { Main } from './components';
import NavProvider from './context/NavContext';



function App() {
  return (
    <div>
			<div className='appContainer'>
			  <NavProvider>
				  <Nav />
				  <Main />
			  </NavProvider>
		  </div>
		</div>
   
  );
}

export default App;
