import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page2.css';
import hikeImage1 from "../icons/hike1.jpg"
import hikeImage2 from "../icons/hike2.jpg"
import aboutMe from "../icons/aboutme.jpg"
import bg_2 from "../icons/bg_2.jpeg"



const About = () => {
	const aboutRef = useNav('About');

	return (
		<section ref={aboutRef} id='aboutContainer' style={{backgroundColor:"rgb(202, 225, 240)"}}>
			<p className='rounded p-2'>
                <img className="portfolioLinks"  style={{height:"35vw", width:"auto"}} src={aboutMe} alt="shreya" />
                <br/><br/><br/>
            </p>
			<div>
				<h3 className='about'>ABOUT ME</h3>
                <br/>
				<p> I am a Data Analyst, pursuing my master’s in Business Analytics from DePaul University, Chicago. Born and raised in Mumbai, India with an up-bringing 
                    of always discovering something more and as a child, I was always taught to be curious to Know the” Why of things”.  <br/> <br/> 
                    My Past experience in the field of Economics & finance allowed me to understand that I aspire to be a rationalist and thus working with data helps me develop some of 
                    the virtues of Rationality such as Curiosity, & Empiricism (what can more surely resolve an argument than a well-designed A/B test?)<br/> <br/> 
                    When it was time to become a student, I naturally gravitated towards tech-heavy education such as coding in Python and R programming.
                    However, I felt that pure engineering was too abstract for me, and I had a creative urge to build something that had a practical use in the real
                    world such as Storytelling using data. So, I decided to get started with Data Analytics and discovering data science was a revelation, and here was 
                    the opportunity to build advanced creative dashboards using Tableau based on numbers and using statistical models, catering to both my creative mentality and my inner data geek. <br/> <br/> 
                    People would describe me as a creative thinker as I like to explore alternative solutions to problems, and I have an open mind about what works best.
                    Some would also look at me as an eternal optimist. I never let little frustrations set me back and I’m always looking for solutions right away. 
                    Over the weekends I usually love to paint, play volleyball and explore the city. I have a  “Lets order some pizza and watch an old movie” personality.
                </p> 
            </div>
		</section>
	);
};

export default About;
  